import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import {Box, Link} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import theme from 'components/mui-theme';
import {CboFeatureFlag} from "components/CboFeatureFlagEditor/featureFlagTypes";

interface ClientFieldsSettingsProps {
  fields?: ClientField[];
  featureFlags: CboFeatureFlag[],
}

interface ClientField {
  label: string;
  name: string;
  enabled: boolean;
  has_options: boolean;
  has_custom_settings: boolean;
}

const ClientFieldsSettings: React.FC<ClientFieldsSettingsProps> = ({fields, featureFlags}: ClientFieldsSettingsProps) => {
  const [clientFields, setClientFields] = useState<ClientField[]>(fields || []);

  const handleChange = async (index: number) => {
    const newFields = [...clientFields];
    newFields[index].enabled = !newFields[index].enabled;
    setClientFields(newFields);

    // Get the CSRF token from the meta tags
    const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");

    // Submit the updated field state to your API
    const field = newFields[index];

    // Serialize data to x-www-form-urlencoded format
    const formData = new URLSearchParams();
    formData.append("_method", "patch");
    formData.append("authenticity_token", csrfToken || "");
    formData.append("field[enabled]", field.enabled ? "1" : "0");

    try {
      const response = await fetch(`/settings/fields/${field.name}/toggle_enabled`, {
        method: 'POST',
        headers: {
          'Accept': 'text/javascript',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: formData.toString()
      });
    } catch (error) {
      console.error(JSON.stringify(error));
      throw error;
    }
  };

  const generateUrl = (field: ClientField, path: string) => `/settings/fields/${field.name}/${path}`;

  const hasBarcodesFeature = featureFlags.some(featureFlag => featureFlag.name === 'barcodes');

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={3} padding={2}>
        <Grid item xs={6}>
          <strong>Field name</strong>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <strong>Enabled</strong>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} padding={2}>
        {/* DYNAMIC FIELDS */}
        {clientFields.map((field, index) => {
          // Early return if barcode feature is disabled for barcode_enable field
          if (field.name === 'barcode_enable' && !hasBarcodesFeature) return null;

          return (
            <React.Fragment key={index}>
              <Grid item xs={10}>
                {/* Link to the default field options selection if this is a Select field with configurable values */}
                {field.has_options && <Link href={generateUrl(field, 'field_options')} sx={{textDecoration: "none"}}>{field.label}</Link>}

                {/* Link to the custom field settings if this is a field with configuration unique to it*/}
                {field.has_custom_settings && <Link href={generateUrl(field, '')} sx={{textDecoration: "none"}}>{field.label}</Link>}

                {/* Otherwise, just display the field label */}
                {!field.has_options && !field.has_custom_settings && <span>{field.label}</span>}
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                  <Switch
                    checked={field.enabled}
                    onChange={() => handleChange(index)}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                </Box>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </ThemeProvider>
  );
};

export default ClientFieldsSettings;
