import React from 'react';
import {Grid, FormControl, InputLabel, MenuItem, Select, TextField, Button, Snackbar, Alert} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import theme from "components/mui-theme";

type AgeType = 'dob' | 'age' | 'category';

const AGE_TYPES: AgeType[] = ['dob', 'age', 'category'];

const AGE_TYPE_LABELS: { [key in AgeType]: string } = {
  'dob': 'Date of birth',
  'age': 'Age',
  'category': 'Age category'
}

interface AgeSettingsProps {
  seniorMinAge?: number;
  defaultAgeType?: "dob" | "age" | "category";
}

/**
 * Settings form for the treatment of Client "Age" fields, `age`, `dob` and `age_group`.
 * @param seniorMinAge
 * @param defaultAgeType
 * @constructor
 */
const AgeSettings: React.FC = ({seniorMinAge, defaultAgeType}: AgeSettingsProps) => {

  const [seniorMinAgeState, setSeniorMinAgeState] = React.useState<number>(seniorMinAge || 60);
  const [defaultAgeTypeState, setDefaultAgeTypeState] = React.useState<"dob" | "age" | "category">(defaultAgeType || 'dob');
  const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const payload = {
      senior_min_age: seniorMinAgeState,
      default_age_type: defaultAgeTypeState
    };

    const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");

    try {
      const response = await fetch('/settings/fields/age', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken || ''
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        // Open the Snackbar to show success message
        setSuccessMessageOpen(true);
      }
    } catch (error) {
      setErrorMessageOpen(true)
    }
  };

  return (
    <>
      <CssBaseline/>
      <ThemeProvider theme={theme}>
        <form onSubmit={handleSubmit}>
          <Grid
            container spacing={2}>
            <Grid item xs={12} sm={12}>
              <p>
                You can change the minimum age that qualifies a person as being a "senior" in your system.
              </p>
              <FormControl fullWidth>
                <TextField
                  color={'primary'}
                  label="'Senior' minimum age"
                  id="senior-min-age"
                  name={"cbo[senior_min_age]"}
                  type="number"
                  inputProps={{min: 40, max: 150}}
                  required={false}
                  value={seniorMinAgeState}
                  onChange={(event) => {
                    setSeniorMinAgeState(parseInt(event.target.value));
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} sx={{marginTop: 2}}>
              <p>
                You can choose your organization's default type of age selection that will show when editing clients'
                profiles.
              </p>
              <FormControl fullWidth>
                <InputLabel id="default-age-type-select">Default age type</InputLabel>
                <Select
                  color={'primary'}
                  label={'Default age type'}
                  labelId={'default-age-type-select'}
                  id="default-age-type"
                  name={"cbo[default_age_type]"}
                  defaultValue=""
                  value={defaultAgeTypeState}
                  required={false}
                  inputProps={{style: {color: 'black'}}}
                  onChange={(event) => {
                    setDefaultAgeTypeState(event.target.value as "dob" | "age" | "category");
                  }}
                >
                  <MenuItem value="">Select an option</MenuItem> {/* Default "empty" option */}
                  {AGE_TYPES.map((option) => (
                    <MenuItem key={option} value={option}>
                      {AGE_TYPE_LABELS[option]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div className="actions" style={{marginTop: 12}}>
            <Button variant="contained" size="large" color="primary" type="submit" name="commit">
              Submit
            </Button>
          </div>

          {/* Success snackbar */}
          <Snackbar
            open={successMessageOpen}
            autoHideDuration={6000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            onClose={() => setSuccessMessageOpen(false)}
          >
            <Alert onClose={() => setSuccessMessageOpen(false)} severity="success" sx={{width: '100%'}}>
              Settings successfully updated
            </Alert>
          </Snackbar>

          {/* Error snackbar */}
          <Snackbar
            open={errorMessageOpen}
            autoHideDuration={6000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            onClose={() => setErrorMessageOpen(false)}
          >
            <Alert onClose={() => setErrorMessageOpen(false)} severity="error" sx={{width: '100%'}}>
              There was an error updating settings. Contact support if this persists.
            </Alert>
          </Snackbar>
        </form>
      </ThemeProvider>
    </>
  );
};

export default AgeSettings;
