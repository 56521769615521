import React, {createRef, useEffect, useState} from 'react';
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {Form} from "@rjsf/mui";
import validator from '@rjsf/validator-ajv8';
import {ThemeProvider} from "@mui/material/styles";
import theme from "components/mui-theme";
import {IDynoForm} from "components/Forms/dynoFormTypes";
import useParentSubmit from "components/Forms/useParentSubmit";
import "./dynoFormStyles.scss"

interface IDynoFormProps {
  form?: IDynoForm;
  formData?: any;
  showSubmit?: boolean;
  disabled?: boolean;
  deferToParent?: boolean;
  showTitle?: boolean;
  attachedModelName?: string;
}

/**
 * Dynamic form viewer powered by RJSF
 * @param form - The DynoForm object to render as a DynoForm view.
 * @param formData - The data to populate the form with. Defaults to an empty object.
 * @param showSubmit - Whether to show a submit button on the form. As of writing, the submit button has no behavior.
 * Defaults to false.
 * @param deferToParent - Whether to attach this form's params to a parent form element. Defaults to true.
 * @param disabled - Whether to allow input to the form (aka readonly on everything). Defaults to false.
 * @param showTitle - Whether to show the title of the form. Defaults to false.
 * @param attachedModelName - The name of the model this form is attached to. Defaults to undefined.
 * @constructor
 */
const DynoForm: React.FC<IDynoFormProps> = ({
  form,
  formData = {},
  showSubmit = false,
  deferToParent = true,
  disabled = false,
  showTitle = false,
  attachedModelName
}: IDynoFormProps) => {
  const jsonSchemaArg = form?.json_schema || {};
  const uiSchemaArg = form?.ui_schema || {};
  const formRef = createRef<any>();
  const submitFormRef = createRef<any>();

  const [currentFormData, setCurrentFormData] = useState(formData);
  const [isRerendered, setIsRerendered] = useState(false); // New state to track rerender
  const [jsonSchema, setJsonSchema] = useState<RJSFSchema>(jsonSchemaArg);
  const [uiSchema, setUiSchema] = useState<UiSchema>(uiSchemaArg);
  const [isInputValid, setIsInputValid] = useState<boolean>(true);

  /**
   * Hijack the parent Rails Form's submit method and add some form data to it from this component.
   * allows the embedding of react forms in Rails ones so we can replace pieces of existing forms incrementally.
   */
  useParentSubmit({
    currentFormData,
    submitFormRef,
    formRef,
    setIsInputValid,
    deferToParent,
    attachedModelName
  });

  // Listen and rerender on underlying schema updates, e.g. if you are previewing an edited form
  useEffect(() => {
    setJsonSchema(jsonSchemaArg);
  }, [jsonSchemaArg])

  /**
   * Load locally stored form data between Rails UJS page rerenders
   */
  useEffect(() => {
    // Only use sessionStorage if it's a rerender
    if (sessionStorage.getItem("DynoForm/shouldRerender") === "true") {
      const storedData = sessionStorage.getItem('formData');
      if (storedData) {
        setCurrentFormData(JSON.parse(storedData));

        // Hack to reload form
        setUiSchema({})
        const tempUiSchema = {...uiSchema};
        setUiSchema(tempUiSchema);
      }
    }
    sessionStorage.removeItem("DynoForm/shouldRerender")
    sessionStorage.removeItem('formData');

  }, [isRerendered]);

  const onChange = (changeEvent: any) => {
    setCurrentFormData(changeEvent.formData);
    sessionStorage.setItem('formData', JSON.stringify(changeEvent.formData));
  };

  const uiSchemaWithoutSubmit = {...uiSchema};
  if (uiSchemaWithoutSubmit) {
    // We don't save this directly to the UiSchema because it upsets the formBuilder when previewing forms, and should
    // only be a display change, so shouldn't alter that underlying info anyway
    uiSchemaWithoutSubmit["ui:submitButtonOptions"] = {
      props: {},
      norender: !showSubmit
    }
  }

  if (!form?.json_schema) return <span className={'text-muted'}>Nothing to preview yet</span>

  if (!showTitle) {
    uiSchemaWithoutSubmit["ui:options"] = {
      title: "",
    };
  }

  // Bullshit errors come up when you nest a conditional (oneOf) field inside a section,
  // you can reproduce this by making a checkbox and a dependent field inside a section,
  // checking and unchecking the checkbox, and then trying to submit. These are ignored.
  // TODO: This is a wrecking ball solution and may catch other errors we want to see.
  const erroneousErrors: string[] = [
    "must match exactly one schema in oneOf",
    "must be equal to one of the allowed values"
  ]

  try {
    return (
      <ThemeProvider theme={theme}>

        <Form
          className={'dyno-form'}
          ref={formRef}
          noHtml5Validate
          showErrorList='bottom'
          disabled={disabled}
          onChange={onChange}
          schema={jsonSchema}
          uiSchema={uiSchemaWithoutSubmit}
          transformErrors={(errors) => {
            return errors.filter((e) => {
              // Check if e.message includes any string from erroneousErrors
              return !erroneousErrors.some(errString => e.message?.includes(errString));
            });
          }}
          formData={currentFormData}
          validator={validator}
        >
          <button ref={submitFormRef} type="submit" style={{display: "none"}}/>
        </Form>
      </ThemeProvider>
    );
  } catch (e) {
    return <span className={'text-muted'}>Invalid schema</span>
  }
};

export default DynoForm;
