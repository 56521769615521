import React from 'react';
import {Grid, ThemeProvider} from "@mui/material";
import {
  ArticleOutlined as ArticleIcon,
  Assessment as AssessmentIcon,
  AssignmentReturnedOutlined as AssignmentReturnedIcon,
  CategoryOutlined as CategoryIcon,
  ContactSupportOutlined as ContactSupportIcon,
  CorporateFareOutlined as CorporateFareIcon,
  DynamicFormOutlined as DynamicFormIcon,
  GavelOutlined as GavelIcon,
  LocalGroceryStoreOutlined as LocalGroceryStoreIcon,
  ShoppingBagOutlined as ShoppingBagIcon,
  LibraryBooksOutlined as LibraryBooksIcon,
  NotesOutlined as NotesIcon,
  PeopleOutlined as PeopleIcon,
  PersonAddOutlined as PersonAddIcon,
  SmsOutlined as SmsIcon,
  TuneOutlined as TuneIcon,
} from "@mui/icons-material";
import './left-navigator.scss';
import theme from "components/mui-theme";
import NavItem from "components/LeftNavigator/NavItem";

interface LeftNavigatorProps {
  admin?: boolean;
  superAdmin?: boolean;
  ordersEnabled?: boolean;
  broadcastEnabled?: boolean;
}

/**
 * Left-side navigation menu that is displayed on all pages.
 * @param admin {boolean} whether the viewing user is an admin
 * @param superAdmin {boolean} whether the viewing user is a super admin
 * @param ordersEnabled
 * @param broadcastEnabled
 * @constructor
 */
const LeftNavigator: React.FC<LeftNavigatorProps> = ({admin, superAdmin, ordersEnabled, broadcastEnabled = false}: LeftNavigatorProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={1} sx={{padding: 1.5}}>
        <Grid item xs={12} className='nav-header'>
          <h5>Client service</h5>
        </Grid>
        <NavItem href="/intake" icon={<PersonAddIcon/>}>Intake</NavItem>
        {ordersEnabled && <>
          <NavItem href="/orders?scope=need_to_be_prepared" icon={<LocalGroceryStoreIcon/>}>Prepare</NavItem>
          <NavItem href="/orders?scope=prepared" icon={<ShoppingBagIcon/>}>Hand out</NavItem>
        </>
        }

        <Grid item xs={12} className='nav-header'>
          <h5>Reporting</h5>
        </Grid>
        <NavItem href="/reports/assistance" icon={<AssessmentIcon/>}>Summary</NavItem>
        <NavItem href="/households/browse" icon={<PeopleIcon/>}>Clients</NavItem>
        <NavItem href="/assistances" icon={<LibraryBooksIcon/>}>History</NavItem>


        <Grid item xs={12} className='nav-header'>
          <h5>Other</h5>
        </Grid>
        {admin && broadcastEnabled &&
          <NavItem href="/sms_messages" icon={<SmsIcon/>}>Broadcast</NavItem>
        }
        <NavItem href="/terms_of_service" icon={<ArticleIcon/>}>Terms</NavItem>
        <NavItem href="http://help.boswell.io/en/" icon={<ContactSupportIcon/>}>Support</NavItem>

        {admin && <>
          <Grid item xs={12} className='nav-header'>
            <h5>Admin</h5>
          </Grid>
          <NavItem href="/settings/cbo" icon={<CorporateFareIcon/>}>My org</NavItem>
          <NavItem href="/settings/cbo/settings" icon={<TuneIcon/>}>General</NavItem>
          <NavItem href="/settings/fields" icon={<NotesIcon/>}>Client fields</NavItem>
          <NavItem href="/settings/assistance_configurations" icon={<CategoryIcon/>}>Assistance types</NavItem>
          <NavItem href="/settings/users" icon={<PeopleIcon/>}>Users</NavItem>
          <NavItem href="/settings/license_agreement" icon={<GavelIcon/>}>License</NavItem>

        </>}

        {superAdmin && <>
          <Grid item xs={12} className='nav-header'>
            <h5>Super admin</h5>
          </Grid>
          <NavItem href="/superadmin/cbos" icon={<CorporateFareIcon/>}>CBOs</NavItem>
          <NavItem href="/superadmin/users" icon={<PeopleIcon/>}>Users</NavItem>
          <NavItem href="/superadmin/fields" icon={<NotesIcon/>}>Client fields</NavItem>
          <NavItem href="/superadmin/csv_client_imports" icon={<AssignmentReturnedIcon/>}>Imports</NavItem>
        </>}
      </Grid>
    </ThemeProvider>

  );
}

export default LeftNavigator;