import React, {Fragment, ReactElement} from 'react';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import theme from 'components/mui-theme';
import { ThemeProvider, Box, Typography, Card } from '@mui/material';
import './dynoFormStyles.scss';

interface IDynoFormViewerProps {
  formData: { [key: string]: any };
  formSchema: RJSFSchema;
  uiSchema: UiSchema;
}

const DynoFormViewer: React.FC<IDynoFormViewerProps> = ({ formData, formSchema, uiSchema }) => {
  type JsonStructure = { [key: string]: string | boolean | JsonStructure };

  /**
   * Adds a smaller element element to the elements array
   * @param level
   * @param key
   * @param elements
   */
  function addElementHeaderToElements(level: number, key: string, elements: React.ReactElement[]) {
    const headerVariant = `h${Math.min(level, 6)}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    const headerElement = (
      <Typography key={`header-${key}-${level}`} variant={headerVariant} className={"element-header"}>
        {key}
      </Typography>
    );
    elements.push(headerElement);
  }

  /**
   * Adds a string element to the elements array
   * @param value
   * @param key
   * @param elements
   */
  function addStringToElements(value: string, key: string, elements: React.ReactElement[]) {
    const lines = value.split('\n').map((line, index) => (
      <Fragment key={`line-${key}-${index}`}>
        {line}
        <br/>
      </Fragment>
    ));
    elements.push(<div key={`div-${key}`}>{lines}</div>);
  }

  function makeSectionHeader(key: string, level: number): ReactElement {
    const headerVariant = `h${Math.min(level, 6)}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    return (
      <Typography key={`header-${key}-${level}`} variant={headerVariant} className={"section-header"}>
        {key}
      </Typography>
    );
  }

  /**
   * Adds a boolean element to the elements array
   * @param key
   * @param value
   * @param elements
   */
  function addBooleanToElements(key: string, value: boolean, elements: React.ReactElement[]) {
    const contentElement = <div key={`div-${key}`}>{value ? 'Yes' : 'No'}</div>;
    elements.push(contentElement);
  }

  /**
   * Renders an RJSF form data as a formatted React element
   * @param json
   * @param currentUiSchema
   * @param currentFormSchema
   * @param level
   */
  function renderFormData(json: JsonStructure, currentUiSchema: UiSchema, currentFormSchema: RJSFSchema, level: number = 4): React.ReactElement  {
    if (level > 6) level = 6; // Max out at h6
    const elements: React.ReactElement[] = [];

    // Determine the order of fields
    const fieldOrder = Array.isArray(currentUiSchema["ui:order"])
      ? currentUiSchema["ui:order"]
      : Object.keys(currentFormSchema.properties || {});

    fieldOrder.forEach((key: string) => {
      if (json.hasOwnProperty(key)) {
        const value = json[key];

        if (typeof value !== 'object' && value !== null) {
          addElementHeaderToElements(level, key, elements);
        }

        // Handling simple fields (non-objects)
        if (typeof value === 'string') {
          addStringToElements(value, key, elements);
        }

        else if (typeof value == 'boolean') {
          addBooleanToElements(key, value, elements);
        }

        // Add section elements
        else {
          if (typeof value === 'object' && value !== null) {
            // Handling nested objects
            if (typeof value === 'object' && currentFormSchema.properties && currentFormSchema.properties[key]) {
              const nestedFormSchema = currentFormSchema.properties[key] as RJSFSchema;
              const nestedUiSchema = currentUiSchema[key] || {};
              elements.push(
                <Fragment key={`fragment-${key}`}>
                  <Card className={"section-content"} sx={{boxShadow: 2, px: 2}}>
                    {makeSectionHeader(key, level)}
                    {renderFormData(value, nestedUiSchema, nestedFormSchema, level + 1)}
                  </Card>
                </Fragment>
              );
            }
          }
        }
      }
    });
    return <Fragment>{elements}</Fragment>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={"dyno-form-viewer"}>
        {formSchema.title &&
          <Typography variant={"h3"}>
            {formSchema.title}
          </Typography>
        }
        <Box mt={2}>
          {formSchema.properties && renderFormData(formData, uiSchema, formSchema)}
        </Box>
      </div>

    </ThemeProvider>
  );
};

export default DynoFormViewer;
