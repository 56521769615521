import {RefObject, useEffect} from "react";

interface IUseParentSubmitProps {
  currentFormData: any;
  submitFormRef:  RefObject<any>;
  formRef:  RefObject<any>;
  setIsInputValid: any;
  deferToParent: boolean;
  attachedModelName?: string;
}

const useParentSubmit = ({currentFormData, submitFormRef, formRef, setIsInputValid, deferToParent, attachedModelName}: IUseParentSubmitProps) => {

  // Default model name to attach FormData to
  const DEFAULT_MODEL_NAME = 'assistance'
  attachedModelName = attachedModelName || DEFAULT_MODEL_NAME;
  // Special case for intake_registrations where their form has a legacy label of "intake_household" but the model
  // is still an "intake_registration".
  const formDomId = attachedModelName == "intake_registration" ? "intake_household" : attachedModelName

  useEffect(() => {
    // Event handler for form submission
    const handleSubmit = (e: Event) => {
      const form = e.target as HTMLFormElement;
      let submitButtonValue = '';

      // Check if the submit event is triggered by a submit button
      // Using type assertion for compatibility
      const submitEvent = e as any;
      if (submitEvent.submitter) {
        const submitter = submitEvent.submitter as HTMLInputElement;
        submitButtonValue = submitter.value;
      }

      if (currentFormData) {
        sessionStorage.setItem('formData', JSON.stringify(currentFormData))
        form.addEventListener('ajax:beforeSend', function (event: any) {
          event.preventDefault(); // prevent Rails UJS from continuing

          // Continue with async behavior
          void handleAsyncSubmit(event, form, submitButtonValue);
        });
      }
    };

    // Async handler for form submission (you can't put an asynt method directly in the event handler because it returns
    // a promise, which the event handler doesn't like or want)
    const handleAsyncSubmit = async (event: any, form: HTMLFormElement, buttonName: string)=> {

      // Manually run validation on the form
      submitFormRef.current.click();
      const isValid = formRef.current.validateForm();
      setIsInputValid(isValid)
      if (!isValid) return;

      // Attach form data to the parent "formData" object that is submitted with the rest of the page
      const formData = new FormData(form);
      if (buttonName) {
        formData.append(`${attachedModelName}[button]`, buttonName)
      }
      formData.append(`${attachedModelName}[form_data]`, JSON.stringify(currentFormData));

      // Extract URL and method from the form
      // We are pressing an invisible submit button because RJSF does not expose a way to show UI validation
      // errors like it would on a normal submit.
      // See: https://github.com/rjsf-team/react-jsonschema-form/issues/500
      const actionURL = form.getAttribute('action') || '';
      const method = form.getAttribute('method') || 'POST';

      try {
        const response = await fetch(actionURL, {
          method: method,
          body: formData,
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (response.headers.get('Content-Type')?.includes('javascript')) {
          const script = await response.text();
          eval(script);  // Evaluates the returned JS
        } else {
          const data = await response.json();
          console.log('Success:', data);
        }

      } catch (error) {
        console.error('Error:', error);
      }
    }

    // Add event listener to the document
    if (deferToParent) {
      const formElement = document.getElementById(`${formDomId}_form`);
      formElement?.addEventListener('submit', handleSubmit, true); // Using the capture phase
    }

    // Cleanup: remove event listener on unmount
    return () => {
      document.getElementById(`${formDomId}_form`)?.removeEventListener('submit', handleSubmit, true);
      document.removeEventListener('ajax:beforeSend', handleSubmit, true);
    };
  }, [currentFormData, submitFormRef, deferToParent]);

}

export default useParentSubmit;