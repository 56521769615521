import React, {useEffect, useState} from 'react';
import {Button, TextField, RadioGroup, FormControlLabel, Radio, Typography} from "@mui/material";
import theme from "components/mui-theme";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {isMobileDevice} from "helpers/mobileHelper";
import dayjs from "dayjs";

interface Client {
  date_of_birth?: string;
  age?: number;
  age_group?: string;
}

interface IAgePickerProps {
  client?: Client;
  required?: boolean;
  model_name?: string;
  default_age_type?: "dob" | "age" | "category";
}

/**
 * Component for picking age, date of birth, or age group for a given client. Provides 3 inputs, and allows toggling
 * between them with buttons.
 * @param client
 * @param model_name
 * @param default_age_type
 */
const AgePicker: React.FC<IAgePickerProps> = ({
  client,
  model_name,
  default_age_type,
  required = true
}: IAgePickerProps) => {
  default_age_type = default_age_type || 'dob';

  const isMobile = isMobileDevice();

  // Mobile devices don't understand the date input type
  let dobFormatted = '';
  if (isMobile) {
    dobFormatted = client?.date_of_birth ? dayjs(client.date_of_birth).format("MM/DD/YYYY") : '';
  } else {
    dobFormatted = client?.date_of_birth ? dayjs(client.date_of_birth).format("YYYY-MM-DD") : '';
  }

  const [inputType, setInputType] = useState<string>(default_age_type);
  const [age, setAge] = useState<string>(client?.age ? client.age.toString() : '');
  const [dobString, setDobString] = useState<string>(
    dobFormatted
  );
  const [dateForServer, setDateForServer] = useState<string>(client?.date_of_birth || '');
  const [ageGroup, setAgeGroup] = useState<string>(client?.age_group || '');
  const [errors, setErrors] = useState<any>(undefined);


  if (client == undefined) throw new Error('Client is undefined');

  const ageHiddenStyle = {
    display: inputType === 'age' ? 'block' : 'none'
  };

  const dobHiddenStyle = {
    display: inputType === 'dob' ? 'block' : 'none'
  };

  const categoryHiddenStyle = {
    display: inputType === 'category' ? 'block' : 'none'
  };

  /**
   * Load locally stored form data between Rails UJS page rerenders
   */
  useEffect(() => {
    // Only use sessionStorage if it's a rerender
    if (sessionStorage.getItem("AgePicker/shouldRerender") === "true") {
      const storedInputType = sessionStorage.getItem('AgePicker/inputType');
      const storedAge = sessionStorage.getItem('AgePicker/age');
      const storedDob = sessionStorage.getItem('AgePicker/dateOfBirth');
      const storedAgeGroup = sessionStorage.getItem('AgePicker/ageGroup');
      const errors = JSON.parse(sessionStorage.getItem('registration/errors') || '{}');

      setErrors(errors)
      setInputType(storedInputType || default_age_type || 'dob')
      setAge(storedAge || '');
      setDobString(storedDob || '');
      setAgeGroup(storedAgeGroup || '');
      setDateForServer(dayjs(storedDob, "MM/DD/YYYY").format("YYYY-MM-DD"))
    }

    // Clear UJS-rerender-saved data.
    ['inputType', 'age', 'dateOfBirth', 'ageGroup', 'inputType', 'shouldRerender'].forEach(
      key => sessionStorage.removeItem(`AgePicker/${key}`)
    );
    sessionStorage.removeItem('registration/errors');
  }, []);

  // Cache data to sessionStorage on form submit
  useEffect(() => {
    const handleSubmit = () => {
      sessionStorage.setItem('AgePicker/inputType', inputType);
      sessionStorage.setItem('AgePicker/age', age);
      sessionStorage.setItem('AgePicker/dateOfBirth', dobString);
      sessionStorage.setItem('AgePicker/ageGroup', ageGroup);
    }

    const formDomId = model_name == "intake_registration" ? "intake_household" : model_name
    const formElement = document.getElementById(`${formDomId}_form`);
    formElement?.addEventListener('submit', handleSubmit, true); // Using the capture phase

    return () => {
      formElement?.removeEventListener('submit', handleSubmit, true);
    }

  }, [inputType, age, dobString, ageGroup]);


  return (
    <ThemeProvider theme={theme}>
      <div id={"form-group-age"} style={{marginBottom: 18}}>
        <div  style={{marginTop: 12, marginBottom: 6}}>
          <label className={"form-label"} style={{marginBottom: 8}}>Date of birth or age</label>
          <div style={ageHiddenStyle}>
            <TextField
              label="Age"
              name={`${model_name}[age]`}
              type="text"
              value={age}
              onChange={(event) => {
                setAge(event.target.value);
                sessionStorage.setItem('AgePicker/age', event.target.value);
                setAgeGroup('');
                setDobString('');
                setDateForServer('');
              }}
            />
          </div>
          <div style={dobHiddenStyle}>
            {isMobile && <>

              <TextField
              label="Date of Birth"
              value={dobString}
              placeholder={'mm/dd/yyyy'}
              onChange={(event) => {
                let value: string = event.target.value;
                value = value.replace(/[^0-9]/g, '');  // Remove non-digit characters

                if (value.length > 8) value = value.substring(0, 8);  // Limit to 8 digits before formatting

                if (value.length > 4) value = `${value.slice(0, 4)}/${value.slice(4)}`;
                if (value.length > 2) value = `${value.slice(0, 2)}/${value.slice(2)}`;

                if (value.length <= 10) {
                  setDobString(value);  // For display
                  const formattedValueForServer = dayjs(value, "MM/DD/YYYY").format("YYYY-MM-DD");
                  setDateForServer(formattedValueForServer);  // For server
                }
              }}
              InputLabelProps={{shrink: true}}
            />
              <input
                type="hidden"
                name={`${model_name}[date_of_birth]`}
                value={dateForServer}
              />
            </>
            }

            {!isMobile && <TextField
              label="Date of Birth"
              name={`${model_name}[date_of_birth]`}
              type="date"
              value={dobString}
              onChange={(event) => {
                sessionStorage.setItem('AgePicker/dateOfBirth', event.target.value);
                setDobString(event.target.value);
                setAge('');
                setAgeGroup('');
              }}
              InputLabelProps={{shrink: true}}
            />
            }
          </div>
          <div style={categoryHiddenStyle}>
            <RadioGroup
              value={ageGroup}
              name={`${model_name}[age_group]`}
              onChange={(event) => {
                sessionStorage.setItem('AgePicker/ageGroup', event.target.value);
                setAgeGroup(event.target.value);
                setDobString('');
                setAge('');
              }}>
              <FormControlLabel value="child" id={"label-age-group-child"} control={<Radio/>} label="Child"/>
              <FormControlLabel value="adult" id={"label-age-group-adult"} control={<Radio/>} label="Adult"/>
              <FormControlLabel value="senior" id={"label-age-group-senior"} control={<Radio/>} label="Senior"/>
            </RadioGroup>
          </div>
        </div>
        <div>
          {inputType !== 'age' &&
            <Button
              variant="outlined"
              id={"age-age-button"}
              color="primary"
              style={{marginRight: 12}}
              onClick={() => {
                sessionStorage.setItem('AgePicker/inputType', 'age');
                setInputType('age')
              }}
            >
              Use age
            </Button>
          }
          {inputType !== 'dob' &&
            <Button
              variant="outlined"
              id={"age-dob-button"}
              color="primary"
              style={{marginRight: 12}}
              onClick={() => {
                sessionStorage.setItem('AgePicker/inputType', 'dob');
                setInputType('dob')
              }}>
              Use date of birth
            </Button>
          }
          {inputType !== 'category' &&
            <Button
              variant="outlined"
              id={"age-group-button"}
              color="primary"
              onClick={() => {
                sessionStorage.setItem('AgePicker/inputType', 'category');
                setInputType('category')
              }}>
              Use age category
            </Button>
          }
          {/*Errors*/}
          <div className={"form-input-hint text-error"}>
            {errors && errors["age"]}
          </div>
        </div>
      </div>

    </ThemeProvider>
  );
}

export default AgePicker;
