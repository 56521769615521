import React, {useState} from 'react';
import {Switch, FormGroup, FormControlLabel, Container, Typography} from '@mui/material';
import {headers} from "helpers/fetchHelper";
import {CboFeatureFlag} from "components/CboFeatureFlagEditor/featureFlagTypes";

// Define an interface for the shape of your feature flags object
interface IFeatureFlagEditorProps {
  existingFlags: any,
  cboId: number;
}

// All optional feature flags
// TODO: This can live here in early drafts but should move to a dedicated, probably backend-residing definition
//       for numerous feature flags.
const initialFeatureFlags: CboFeatureFlag[] = [
  {
    name: "referral_links/basic",
    label: "Basic referral links",
    enabled: false
  },
  {
    name: "barcodes",
    label: "Barcodes",
    enabled: false
  },
  {
    name: "dynamic_forms/assistance_type",
    label: "(Deprecated) Dynamic forms on Assistance Types",
    enabled: false
  },
  {
    name: "dynamic_forms/intake",
    label: "(Deprecated) Dynamic forms on intake flow",
    enabled: false
  },
  {
    name: "dynamic_forms/advanced",
    label: "Advanced dynamic forms",
    enabled: false
  },
  {
    name: "broadcasts",
    label: "SMS Broadcasts",
    enabled: false
  }
];

const CboFeatureFlagEditor: React.FC<IFeatureFlagEditorProps> = ({cboId, existingFlags}: IFeatureFlagEditorProps) => {
  const [featureFlags, setFeatureFlags] = useState<CboFeatureFlag[]>(
    initialFeatureFlags.map(flag => {
      const existingFlag = existingFlags.find((eFlag: CboFeatureFlag) => eFlag.name === flag.name);
      return existingFlag ? {...flag, enabled: existingFlag.enabled} : flag;
    })
  );

  const handleToggle = (index: number) => {
    const updatedFlags = featureFlags.slice();
    updatedFlags[index].enabled = !updatedFlags[index].enabled;
    setFeatureFlags(updatedFlags);

    fetch(`/superadmin/cbos/${cboId}/edit_feature_flag`, {
      method: 'PATCH',  // or 'PUT' if that's the desired method in your Rails app
      headers: headers(),
      body: JSON.stringify(updatedFlags[index]),
    });
  };

  return (
    <Container>
      <FormGroup>
        {featureFlags.map((flag, index) => (
          <FormControlLabel
            key={index}
            control={
              <Switch
                checked={flag.enabled}
                onChange={() => handleToggle(index)}
                name={flag.label}
              />
            }
            label={flag.label}
          />
        ))}
      </FormGroup>
    </Container>
  );
};

export default CboFeatureFlagEditor;
