import React from 'react';

const HelloWorld: React.FC = () => {
  return (
    <div>
      Hello, world!
    </div>
  );
}

export default HelloWorld;