import {createTheme} from '@mui/material/styles';
import {lighten} from "@mui/material";

export const colors = {
  primary: '#698dd1',
  secondary: '#6c757d',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#e85600',
  danger: '#dc3545',
  light: '#f8f9fa',
	dark: '#343a40',
};

const theme = createTheme({
	zIndex: {
		mobileStepper: 900,
		speedDial: 950,
		appBar: 1020,
		drawer: 1030,
		modal: 1050,
		snackbar: 1060,
		tooltip: 1070,
	},
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
		success: {
			main: colors.success,
		},
		info: {
			main: colors.info,
		},
		warning: {
			main: colors.warning,
		},
		error: {
			main: colors.danger,
		},
		getContrastText: (background: string) => {
			return '#fff';
		}
	},
	typography: {
		fontFamily: 'Quicksand, sans-serif',
		h1: {
			fontWeight: 500,
			fontSize: "1.75rem",
			color: colors.dark
		},
		h2: {
			fontWeight: 500,
			fontSize: "1.2rem",
			color: colors.primary,
		},
		h3: {
			fontWeight: 500,
			fontSize: "1.1rem",
			color: colors.primary,
		},
		h4: {
			fontWeight: 500,
			fontSize: "1.0rem",
			color: colors.primary,
		},
		h5: {
			fontWeight: 500,
			fontSize: "0.9rem",
			color: colors.primary,
		},
		h6: {
			fontWeight: 500,
			fontSize: "0.8rem",
			color: colors.primary,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none', // Removes uppercase transformation
					color: 'white',
					fontWeight: 500,
					fontSize: 18,
					paddingTop: '0.1rem',
					paddingBottom: '0.1rem',
					backgroundColor: colors.primary,
					'&:hover': {
						backgroundColor: lighten(colors.primary, 0.5), // makes the color 10% darker
					},
				},
				outlined: {
					color: colors.primary,
					backgroundColor: 'transparent',
					borderColor: colors.primary
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					padding: '0.5rem'
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
					color: colors.primary,
					'&:visited': {
						color: colors.primary,
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					paddingTop: '0.2rem',
					paddingBottom: '0.2rem',
					height: '1.0rem'
				},
			}
		}
	}
});

export default theme;
