import React, {useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import theme from 'components/mui-theme';
import {Grid, Typography, Tabs, Tab, Box, Badge} from '@mui/material';
import {Lock as LockIcon} from '@mui/icons-material';
import './dynoFormBuilderOverrides.scss';
import DynoForm from 'components/Forms/DynoForm';
import {IDynoForm} from "components/Forms/dynoFormTypes";

// @ts-ignore (due to ignoring no extant type defs)
import {FormBuilder as FormBuilderV2} from '@ginkgo-bioworks/react-json-schema-form-builderV2';
import {FormBuilder as FormBuilderV3} from '@ginkgo-bioworks/react-json-schema-form-builder';

// Scoped file from our Bootstrap 5 fork
import "bootstrap/scss/bootstrap.scss"

// Ace editor for JSON editing
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";

interface IDynoFormBuilderProps {
  dynoForm?: IDynoForm;
  setDynoForm?: (dynoForm: IDynoForm) => void;
  showSchemaEditor?: boolean;
  formBuilderVersion?: string;
}

const DynoFormBuilder: React.FC<IDynoFormBuilderProps> = ({dynoForm, setDynoForm, showSchemaEditor = false, formBuilderVersion}) => {
  if (!dynoForm || !setDynoForm) return <span className={'text-muted'}>Invalid form</span>
  if (!dynoForm?.json_schema) return <span className={'text-muted'}>Invalid schema</span>

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>

      {/* Hide the internal FormBuilder's advanced options button for simple use-cases */}
      {formBuilderVersion == "v2" && (
        <style>{`
          [id*="editinfo"] {
            display: none;
          }
        `}</style>
      )}

      <Typography variant={'h1'}>Form Builder</Typography>
      <p className={'text-muted'}>
        Build a dynamic form that will show up under the "dynamic form" section of a Client form.
      </p>

      <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 2}}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Form Builder"/>
          <Tab label="Preview"/>
          {showSchemaEditor &&  <Tab label={
            <>
              <Badge
                badgeContent={<LockIcon sx={{height: "12px", width: "12px"}} />}
                color="primary"
              >
                Form Schema
              </Badge>
            </>
          }/>}
        </Tabs>
      </Box>

      <Box hidden={tabValue !== 0}>
        <Grid container>
          <Grid item xs={12}>
            {(formBuilderVersion && formBuilderVersion === "v2") &&
              <FormBuilderV2
                className={'bootstrap-scope'}
                schema={JSON.stringify(dynoForm.json_schema)}
                uischema={JSON.stringify(dynoForm.ui_schema)}
                onChange={(newSchema: string, newUiSchema: string) => {
                  const updatedForm: IDynoForm = {};
                  if (newSchema) updatedForm.json_schema = JSON.parse(newSchema);
                  if (newUiSchema) updatedForm.ui_schema = JSON.parse(newUiSchema);
                  setDynoForm({...dynoForm, ...updatedForm});
                }}
                mods={{
                  deactivatedFormInputs: ["array", "integer", "time", "password", "radio"],
                  labels: {
                    formNameLabel: "Form name",
                    formDescriptionLabel: "Description"
                  },
                  tooltipDescriptions: {
                    cardObjectName: "The name of this form element",
                  },
                  showFormHead: true,
                }}
              />
            }
            {(formBuilderVersion && formBuilderVersion === "v3") &&
              <FormBuilderV3
                className={'bootstrap-scope'}
                schema={JSON.stringify(dynoForm.json_schema)}
                uischema={JSON.stringify(dynoForm.ui_schema)}
                onChange={(newSchema: string, newUiSchema: string) => {
                  const updatedForm: IDynoForm = {};
                  if (newSchema) updatedForm.json_schema = JSON.parse(newSchema);
                  if (newUiSchema) updatedForm.ui_schema = JSON.parse(newUiSchema);
                  setDynoForm({...dynoForm, ...updatedForm});
                }}
                mods={{
                  deactivatedFormInputs: ["array", "integer", "time", "password", "radio"],
                  labels: {
                    formNameLabel: "Form name",
                    formDescriptionLabel: "Description"
                  },
                  tooltipDescriptions: {
                    cardObjectName: "The name of this form element",
                  },
                  showFormHead: true,
                }}
              />
            }

          </Grid>
        </Grid>
      </Box>

      {/* Preview */}
      <Box hidden={tabValue !== 1}>
        <Grid container>
          <Grid item xs={12}>
            {!dynoForm.json_schema && <span className={'text-muted'}>Nothing to preview yet</span>}
            {dynoForm.json_schema && <DynoForm form={dynoForm}/>}
          </Grid>
        </Grid>
      </Box>

      {/* JSON Editor */}
      <Box hidden={tabValue !== 2}>
        <Grid container>
          <Grid item xs={12}>
            <Box hidden={tabValue !== 2}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={'h4'}>
                    What's this?
                  </Typography>
                  <p className="text-muted" style={{marginBottom: "1rem"}}>
                    This is the "raw" form definition as it's stored in our database.
                    It's encoded in a format called "JSON" (JavaScript Object Notation), and follows a set of
                    conventions called "JSONSchema."
                  </p>

                  <p className="text-muted" style={{marginBottom: "1rem"}}>
                    Generative AI language tools like ChatGPT can understand JSONSchema form definitions, and can
                    generate them from a prompt.
                  </p>

                  <p className="text-muted" style={{marginBottom: "1rem"}}>
                    Try:
                    <Typography style={{
                      marginLeft: "1rem",
                      backgroundColor: "#eee",
                      padding: "1rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}>
                      "Generate me a social determinants of health screener form in the JSON Schema format"
                    </Typography>

                    Then you can suggest some edits, or paste the results below:
                  </p>

                  <h3>JSON Schema</h3>
                  {!dynoForm.json_schema && <span className={'text-muted'}>Nothing to edit yet</span>}
                  {dynoForm.json_schema && (
                    <AceEditor
                      mode="json"
                      theme="monokai"
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{$blockScrolling: true}}
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      value={JSON.stringify(dynoForm.json_schema, null, 2)}
                      onChange={(newJson) => {
                        try {
                          const parsed = JSON.parse(newJson);
                          setDynoForm({...dynoForm, json_schema: parsed});
                        } catch (err) {
                          setDynoForm({
                            ...dynoForm, json_schema: {
                              "title": "New Form",
                              "description": "A new form",
                              "type": "object"
                            }
                          });
                        }
                      }}
                      setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                      style={{width: '100%'}}
                    />
                  )}

                  <h3>UI Schema</h3>
                  {dynoForm.ui_schema && (
                    <AceEditor
                      mode="json"
                      theme="monokai"
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{$blockScrolling: true}}
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      value={JSON.stringify(dynoForm.ui_schema, null, 2)}
                      onChange={(newJson) => {
                        try {
                          const parsed = JSON.parse(newJson);
                          setDynoForm({...dynoForm, ui_schema: parsed});
                        } catch (err) {
                          setDynoForm({
                            ...dynoForm, ui_schema: {
                              "title": "New Form",
                              "description": "A new form",
                              "type": "object"
                            }
                          });
                        }
                      }}
                      setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                      style={{width: '100%'}}
                    />
                  )}

                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/*<Button>Save</Button>*/}
    </ThemeProvider>
  );
};

export default DynoFormBuilder;
