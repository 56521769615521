import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent, Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {ThemeProvider} from '@mui/material/styles';
import theme from 'components/mui-theme';
import DynoFormBuilder from "components/Forms/DynoFormBuilder";
import {IDynoForm} from "components/Forms/dynoFormTypes";
import {headers} from "helpers/fetchHelper";
import Grid from "@mui/material/Grid";

interface IDynoFormAdderProps {
  form?: IDynoForm;
  associatedModel?: string;
  associatedModelId?: number;
  showSchemaEditor?: boolean;
  formBuilderVersion?: "v2" | "v3";
}

const DynoFormAdder: React.FC<IDynoFormAdderProps> = ({
  form,
  associatedModel,
  associatedModelId,
  showSchemaEditor = false,
  formBuilderVersion
}) => {
  const [dynoForm, setDynoForm] = useState<IDynoForm | undefined>(form);
  const [lastSavedDynoForm, setLastSavedDynoForm] = useState<IDynoForm | undefined>(form);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [jsonIsOpen, setJsonIsOpen] = useState(false);  // State to manage the visibility of the JSON content

  const [isNewForm, setIsNewForm] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (dynoForm?.json_schema !== form?.json_schema || dynoForm?.ui_schema !== form?.ui_schema) {
      setHasUnsavedChanges(true);
    }
  }, [dynoForm])

  /**
   * Persist an empty form to the rails API for a given assistance configuration
   * TODO: This is hardcoded to assistance configurations for an MVP but conceptually could be attached to other things
   */
  const addEmptyForm = async () => {
    setDynoForm({
      json_schema: {
        title: "New Form",
        description: "A new form",
        type: "object"
      },
      ui_schema: {},
    });
    setHasUnsavedChanges(true);
    setIsNewForm(true);
  };

  /**
   * Persist a form to the backend if there are unsaved edits to it
   */
  const saveEditedForm = async () => {

    // Right now this is the only case we support
    if (dynoForm) {
      // POST for new forms
      if (isNewForm ) {
        if (!associatedModel || !associatedModelId) throw new Error("Missing associated model or ID");

        let body: { [key: string]: any } = {};
        body[`associated_model`] = associatedModel;
        body['associated_model_id'] = associatedModelId;
        body.form = dynoForm;

        try {
          const response = await fetch('/settings/forms/', {
            method: 'POST',
            headers: headers(),
            body: JSON.stringify(body),
          });

          if (response.ok) {
            const savedForm = await response.json();

            setDynoForm(savedForm);
            setLastSavedDynoForm(savedForm);
            setHasUnsavedChanges(false);
            setIsNewForm(false);
          }
        } catch (error) {
          throw error;
        }
      }
      // PATCH for existing forms
      else {
        try {
          const response = await fetch(`/settings/forms/${dynoForm.id}`, {
            method: 'PATCH',  // or 'PUT' if that's the desired method in your Rails app
            headers: headers(),
            body: JSON.stringify(dynoForm),
          });

          if (response.ok) {
            const data = await response.json();
            setHasUnsavedChanges(false);
            setIsNewForm(false);
            // Handle the successful update response if needed, e.g., updating local state
          } else {
            // Handle non-successful response, e.g., showing an error message
          }
        } catch (error) {
          throw error;
        }
      }

    } else {
      throw new Error("DynoForm not present in DynoFormAdder on save");
    }
  }

  const handleDelete = async () => {
    if (isNewForm) {
      setDynoForm(undefined);
    } else if (dynoForm) {
      const response = await fetch(`/settings/forms/${dynoForm.id}`, {
        method: 'DELETE',
        headers: headers(),
      });
      setDynoForm(undefined);
      setDeleteDialogOpen(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {dynoForm &&
        <Card key={dynoForm.id} sx={{marginBottom: 2}}>
          <CardContent>
            <Typography variant="h3">{dynoForm.json_schema?.title}</Typography>
            <Typography variant="body2">{dynoForm.json_schema?.description}</Typography>
            <Collapse in={jsonIsOpen}>
              <Typography sx={{fontFamily: "monospace", fontSize: "0.8rem"}}>
                {JSON.stringify(dynoForm.json_schema, null, 2)}
              </Typography>
            </Collapse>
          </CardContent>
          <CardActions>
            <div>
              <Grid container>
                <Grid item xs={12}>
                  <IconButton onClick={() => setEditDialogOpen(true)}><EditIcon/></IconButton>
                  <IconButton onClick={() => setDeleteDialogOpen(true)}><DeleteIcon/></IconButton>
                  {hasUnsavedChanges && <Button variant={"outlined"} onClick={saveEditedForm}>Save</Button>}
                </Grid>
              </Grid>
              <Grid item>
                {hasUnsavedChanges &&
                  <Typography sx={{fontSize: 14}} className={'text-warning text-muted'}>
                    You have unsaved changes
                  </Typography>
                }
              </Grid>


            </div>
          </CardActions>

        </Card>
      }
      {!dynoForm && (
        <Button variant={"outlined"} onClick={addEmptyForm}>Add a form</Button>
      )}

      <Dialog
        open={editDialogOpen}
        onClose={(event, reason) => {
          // Forbid clicking out of modal to close it
          if (reason !== 'backdropClick') {
            setEditDialogOpen(false)
          }
        }}
        sx={{
          '.MuiPaper-root': {   // Targets the inner Paper component of the Dialog
            width: '90%',
            height: '90%',
            maxWidth: 'none',  // This removes the default max width
            maxHeight: 'none'  // This removes the default max height
          }
        }}
      >
        <DialogContent>
          {dynoForm &&
            <DynoFormBuilder
              formBuilderVersion={formBuilderVersion}
              dynoForm={dynoForm}
              showSchemaEditor={showSchemaEditor}
              setDynoForm={setDynoForm}
            />
          }
          {!dynoForm && <span className={'text-muted'}>Nothing to edit yet</span>}
        </DialogContent>
        <DialogActions>

          {hasUnsavedChanges &&
            <Typography sx={{fontSize: 14, marginRight: "1rem"}} className={'text-warning text-muted'}>You have unsaved
              changes</Typography>
          }

          <Button onClick={() => {
            if (hasUnsavedChanges) {
              setCancelDialogOpen(true)
            } else {
              setEditDialogOpen(false)
            }
          }} color="primary">Cancel</Button>

          {/*<Button onClick={() => {*/}
          {/*  // setEditDialogOpen(false)*/}
          {/*}} color="primary">Preview</Button>*/}

          <Button onClick={() => {
            void saveEditedForm();
            setEditDialogOpen(false);
          }} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation*/}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setDeleteDialogOpen(false)
          }} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Cancel confirmation */}
      <Dialog open={cancelDialogOpen} onClose={() => setCancelDialogOpen(false)}>
        <DialogTitle>Confirm Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel? You will lose any unsaved changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setCancelDialogOpen(false)
          }}>
            Continue editing
          </Button>

          <Button onClick={() => {
            setCancelDialogOpen(false)
            setEditDialogOpen(false)
            setHasUnsavedChanges(false)
            setDynoForm(lastSavedDynoForm) // Revert changes
          }}>
            Cancel and revert changes
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DynoFormAdder;
