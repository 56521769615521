import React, {useEffect} from 'react';

const CounterLoader: React.FC = () => {
  useEffect(() => {
    const elements = document.querySelectorAll<HTMLInputElement>('[data-counter]');

    elements.forEach((element) => {
      // Get the corresponding counter div
      const counter = document.querySelector<HTMLDivElement>('#' + element.dataset.counter);

      // Function to update the counter
      const updateCounter = () => {
        if (counter) {
          const length = element.value.length;
          const maxLength = Number(element.dataset.length);
          counter.textContent = `${length}/${maxLength}`;
        }
      };

      // Update the counter immediately and whenever the element's input changes
      updateCounter();
      element.addEventListener('input', updateCounter);

      // Ensure the event listener is removed before Turbolinks cache
      document.addEventListener('turbolinks:before-cache', () => {
        element.removeEventListener('input', updateCounter);
      });
    });
  }, []);

  return null;
};

export default CounterLoader;
