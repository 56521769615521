import React, {ReactElement, ReactNode} from 'react';
import {Box, Grid, Link} from "@mui/material";
import {colors} from "components/mui-theme";

interface NavItemProps {
  href: string;
  children: ReactNode;
  icon: ReactElement;
}

/**
 * A single navigation link in a navigation menu.
 * @param href
 * @param children
 * @param icon
 * @constructor
 */
const NavItem: React.FC<NavItemProps> = ({href, children, icon}: NavItemProps) => {
  const isActive = window.location.href.endsWith(href);

  return (
    <Grid item xs={12} className={`nav-item ${isActive ? 'active' : ''}`}>
      <Box display="flex" alignItems="center">
        {React.cloneElement(icon, {
          fontSize: "small",
          sx: {marginRight: '1rem', color: isActive ? colors.primary : colors.light}
        })}
        <Link href={href}>{children}</Link>
      </Box>
    </Grid>
  );
}

export default NavItem;